/* eslint-disable prettier/prettier */
export default {
  label: {
    ok: 'ஒகே',
    confirm: 'உறுதி',
    cancel: 'ரத்து செய்',
    download: 'டவுன்லோட்',
    submit: 'சமர்ப்பிக்க',
    search: 'தேடு',
    recharge: 'ரீசார்ஜ்',
    balance: 'பேலன்ஸ்',
  },
  tip: {
    success: 'வெற்றிகரமாக',
  },
  text: {},
  defaultPage: {
    noMore: 'மேலும் டேட்டா இல்லை',
  },
  home: {
    scratchOff: 'ஸ்கிராட்ச் ஆஃப்',
    copied:
      'வெற்றிகரமாக காப்பி செய்யப்பட்டது , நண்பர்களுடன் பகிர்ந்து கொள்ளவும்',
    tip: {
      beforDownload: 'ரெஜிஸ்டர் அல்லது லோகின்',
    },
  },
  alert: {
    logout: 'வெளியேறு',
    sureLogout: 'நீங்கள் நிச்சயமாக வெளியேற விரும்புகிறீர்களா?',
  },
  warning: {
    unopen: 'திறக்கப்படாது',
  },
  notification: {
    title: 'அறிவிப்புகள்',
    announcements: 'அறிவிப்புகள்:',
    systemMessage: 'சிஸ்டம் செய்திகள்',
  },
  vip: {
    currentLevel: 'தற்போதைய நிலை',
    collected: 'பெற்றுக்கொள்ளுங்கள்',
    finished: 'முடிவடைந்தது',
    received: 'பெறப்பட்டது',
    recharge: 'ரீசார்ஜ்',
    move: 'நிலை அடைய',
    title: 'விஐபி',
    table: {
      level: 'விஐபி லெவல்',
      reward: 'ரிவார்ட்',
      bouns: 'போனஸ்',
      spin: 'ஸ்பின்',
    },
    success: 'வெற்றிகரமாகப் பெறப்பட்டது',
  },
  referral: {
    copy: 'காப்பி',
    invite: 'இன்வைட்',
    copied: 'வெற்றிகரமாக காப்பி செய்யப்பட்டது',
  },
  luckyspin: {
    add: 'ஆட்',
    spin: 'ஸ்பின்',
    won: 'வின்',
    winner: 'பிக் வின்னர்',
    mySpin: 'மை ஸ்பின்',
    free: 'ஃப்ரீ',
    myfree: 'மை ஃப்ரீ',
    morefree: 'மோர் ஃப்ரீ',
    vip: 'விஐபி ',
    usefreefirst: 'யூஸ் அப்ஃப்ரீ டைம் பஸ்ட்டு',
  },
  'error-pages': {
    refresh: {
      label: {
        title: 'ஏதோ பிழையாகிவிட்டது!',
        'sub-title': 'தயவுசெய்து உங்கள் பக்கத்தைப் புதுப்பிக்கவும்',
        'try-again': 'மீண்டும் முயற்சிக்கவும்',
      },
    },
    'no-data': {
      label: {
        title: 'டேட்டா எதுவும் இல்லை',
      },
    },
    'not-found': {
      label: {
        title: 'தற்போதைய நெட்வொர்க் சிக்னல் மோசமாக உள்ளது',
        'sub-title': 'நெட்வொர்க் சிக்னலைசரிபார்க்கவும் ',
      },
    },
    'no-result': {
      label: {
        title: 'தேடல் ரிசல்ட் இல்லை!',
      },
    },
  },
  share: {
    copy: 'காப்பி',
    invite: 'இன்வைட்',
    'copy-success': 'வெற்றிகரமாக காப்பி செய்யப்பட்டது',
    copied: 'வெற்றிகரமாக காப்பி செய்யப்பட்டது',
  },
  casino: {
    big: 'பிக்',
    win: 'வின்',
    detail: {
      title: 'அதிர்ஷ்டசாலி வீரர்களுக்கு வாழ்த்துக்கள்',
    },
    winPrize: 'வின் பிரைஸ்',
    playnow: 'இப்பொழுதே விளையாடு',
  },
  login: {
    label: {
      login: 'உள்நுழைய',
      skip: 'தவிர்க்கவும்',
      sign: 'பதிவு செய்யவும்',
      next: 'அடுத்தது',
      'select-region': 'பிராந்தியத்தைத் தேர்ந்தெடுக்கவும்',
      'otp-login': 'OTP உள்நுழைவு',
      'password-login': 'பாஸ்வர்ட் உள்நுழைவு',
      'get-otp': 'OTP பெறுக',
      'set-password': 'பாஸ்வர்ட் அமைக்கவும் ',
    },
    tip: {
      'no-phone': 'தொலைபேசி எண்ணை உள்ளிடவும்',
      'no-password': 'பாஸ்வர்ட் உள்ளிடவும்',
      'no-otp': 'OTP உள்ளிடவும்',
      phone: 'தொலைபேசி எண்ணை உள்ளிடவும்',
      password: 'பாஸ்வர்ட் உள்ளிடவும்(6-18)',
      passwordRule:
        'பாஸ்வர்ட் 8~16 ஆக இருக்க வேண்டும் மற்றும் எண்கள் மற்றும் எழுத்துக்கள் இரண்டையும் கொண்டிருக்க வேண்டும்',
      'confirm-password': 'பாஸ்வர்ட் உறுதிப்படுத்தவும்',
      forgot: 'பாஸ்வர்டை மறந்து விட்டீர்களா?',
      'new-account': 'கணக்கு வேண்டும்?',
      'sing-in': 'பதிவு செய்யவும்',
      otp: 'OTP ஐ உள்ளிடவும்',
      'referral-code': 'ரெபரல் கோர்ட',
      confrim18: 'நான் 18+ என்று உறுதி செய்கிறேன்+',
      'allow-notify':
        'இந்த ஃபோனின் எண் மூலம் முக்கியமான வெற்றிகரமான தகவலை உங்களுக்குத் தெரிவிக்க எங்களை அனுமதிக்கவும்.',
      'has-account': 'ஏற்கனவே ஒரு பயனர்?',
      'sign-in': 'உள்நுழைய',
      page18: 'நான் 18+ என்று உறுதி செய்கிறேன்',
      notify:
        'இந்த ஃபோனின் எண் மூலம் முக்கியமான வெற்றிகரமான தகவலை உங்களுக்குத் தெரிவிக்க எங்களை அனுமதிக்கவும் .',
      'pwd-dif': 'இழுவை பாஸ்வர்டை சரிபார்க்கவும்',
      modified: 'பாஸ்வர்ட் வெற்றிகரமாக மாற்றப்பட்டது. மீண்டும் உள்நுழையவும்',
      'game-not-active':
        'மன்னிக்கவும், கேம் இப்பொழுது பராமரிப்பில் உள்ளது. தயவுசெய்து  பிறகு முயற்சிக்கவும்',
    },
  },
  paidSuccess: {
    label: {
      title: 'கட்டணம்',
      subTitle: 'வெற்றிகரமாக செலுத்தப்பட்டது',
      viewOrder: 'ஆர்டர்களைப் பார்க்கவும்',
      backHome: 'மீண்டும் திரும்பு',
    },
    tip: {
      msg: 'உங்கள் டிக்கெட்டுகள் வெற்றிகரமாக வாங்கப்பட்டன. டிரா நேரத்தைக் கவனத்தில் கொள்ளவும், முடிவுகளை உடனடியாகச் சரிபார்க்கவும்.',
    },
  },
  splash: {
    tip: {
      checkingResources: 'ஆதாரங்களைச் சரிபார்க்கிறது...',
      checkingVersion: 'பதிப்பைச் சரிபார்க்கிறது...',
      downloading: 'வளர்ச்சியை பதிவிறக்குகின்றது...',
      resolv:
        'ஆதாரங்களைத் தீர்ப்பது (இந்த செயல்முறை நெட்வொர்க்கைப் பயன்படுத்தாது)',
      alertTitle: 'அப்டேட் தயாராகவுள்ளது',
      restart: 'ரீஸ்டார்ட்',
      alertContent:
        'அப்டேட் தயாராகவுள்ளது.ஆப்-ஐ ரீஸ்டார்ட் செய்வதன் மூலம் அப்டேட் -ஐ பெறுங்கள்...',
    },
  },
  recharge: {
    tip: {
      easyPaymentError:
        'சேவையைப் பெறுவதில் தோல்வியானால், பிற பேமேன்ட் முறைகளை பயன்படுத்தவும்',
    },
  },
  'recharge-page': {
    currentMethod: 'தற்போதைய முறை',
    label: {
      records: 'பதிவுகள்',
      min: 'குறைந்தபட்சம்',
      max: 'அதிகபட்சம்',
      channel: 'ரிசார்ஜ் சேனல்',
      enter: 'ரிசார்ஜ் அமௌன்ட் உள்ளிடவும்',
    },
    tip: {
      payMethodTip:
        'தற்போதைய முறை செயல்படவில்லையெனில் ,மாற்று முறையை பயன்படுத்தவும் .',
      'money-error': 'சரியான தொகை உள்ளிடவும்',
      'paymethod-error': 'கட்டண முறையைத் தேர்ந்தெடுங்கள்',
    },
  },
  datePicker: {
    label: {
      select: 'தேதியைத் தேர்வுசெய்க',
    },
  },
  rebate: {
    tabs: {
      all: 'அனைத்தும்',
      finish: 'முடிக்க வேண்டும்',
      collect: 'கலெக்ட் செய்ய வேண்டும்',
      received: 'பெறப்பட்டது',
    },
    title: 'ரிபேட் ',
    filter: 'பிஹில்டெர் ',
    undone: 'செயல்தவிர்க்கப்பட்டது',
    unclaimed: 'உரிமை கோரப்படவில்லை',
    completed: 'முடிந்தது',
    unfinished: 'முடிக்கப்படவில்லை',
    receive: 'பெறப்பட்டது',
    received: 'பெறப்பட்டது',
    today: 'இன்று',
    yesterday: 'நேற்று',
    betting: 'மதிப்பிடப்பட்ட பேட்டிங்:',
    rebate: 'மதிப்பிடப்பட்ட ரிபேட்:',
    congratulations: 'வாழ்த்துகள்！',
    'get-bonus': 'போனஸைப் பெறுங்கள்',
    rule: {
      betting: 'பந்தய போனஸ்:',
      'betting-detail':
        '{{name}} பந்தயத் தொகைக்கு ஏற்ப பயனர்களுக்குத் தள்ளுபடி செய்யும். நீங்கள் எவ்வளவு அதிகமாக பந்தயம் கட்டுகிறீர்களோ, அவ்வளவு அதிகமாக உங்களுக்கு கிடைக்கும்',
      activity: 'செயல் விளக்கம்:',
      'activity-detail':
        '{{name}} சூப்பர் போனஸ் தள்ளுபடி செயல்பாடு உங்களுக்காக திறந்திருக்கும். ஒவ்வொரு பந்தயத்திற்கும் தள்ளுபடி போனஸ் கிடைக்கும். நீங்கள் வென்றாலும் அல்லது தோற்றாலும் பரவாயில்லை, உங்களுக்கு தினசரி போனஸ் கிடைக்கும்.',
      actrule: 'செயல்பாட்டு விதிகள்:',
      'actrule-detail':
        'அடுத்த நாள் 00:30 மணிக்குப் பிறகு, பந்தய தள்ளுபடி போனஸ் டெய்லிபோனஸ் க்ளெய்ம் நேரம்',
      precaution: 'முன்னெச்சரிக்கை நடவடிக்கைகள்:',
      'precaution-detail-1':
        '-தகுதிவாய்ந்த {{name}} உறுப்பினர்கள் மட்டுமே நடவடிக்கைகளில் பங்கேற்க முடியும்.',
      'precaution-detail-2':
        '-ஒரே தொலைபேசி எண், வங்கி கணக்கு எண். மின்னஞ்சல் முகவரி மற்றும் ஐபி முகவரியை பல கணக்குகளுக்கு பயன்படுத்த முடியாது',
      'precaution-detail-3':
        '-{{name}} போனஸைப் பயன்படுத்தி தனிநபர்கள் அல்லது குழுக்கள் செய்த சட்டவிரோத/மோசடி நடவடிக்கைகள் கண்டறியப்பட்டால் கணக்குகளை முடக்கவோ அல்லது பணம் செலுத்துவதை ரத்துசெய்யவோ உரிமை உண்டு',
    },
  },
  wallet: {
    title: 'மை வாலட்',
    total: 'டோடல் வாலட்',
    recharge: 'ரிசார்ஜ்',
    main: 'மெயின் வாலட்',
    third: '3 வது பார்ட்டி வாலட்',
    transfer: "மாற்றம் நிறைவடைந்தது'ர்",
    translation: 'Translation: {{second}}s',
    transComplete: 'Conversion completed',
    transferNav: {
      recharge: 'ரிசார்ஜ்',
      withdraw: 'வித்த்ரா',
      transfer: 'ட்ரான்ஸ்ஃபர்',
    },
  },
  promotion: {
    title: 'விளம்பரங்கள்',
    go: 'கோ ',
    playNow: 'ப்ளே நாவ்',
    view: 'காண்க',
    detail: 'செயல்பாட்டின் விவரங்கள்',
    join: 'சேரு',
    checkin: 'சரிபார்த்தல்',
    checkinDescription: 'நிறைய ரிவார்டுகளைப் பெற தினமும் செக் இன் செய்யுங்கள்',
  },
};
