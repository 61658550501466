/* eslint-disable quotes */
export default {
  label: {
    ok: 'Ok',
    confirm: 'Confirm',
    cancel: 'Cancel',
    download: 'Download',
    submit: 'Submit',
    search: 'Search',
    recharge: 'Recharge',
    withdraw: 'Withdraw',
    withdrawal: 'Withdrawal',
    transfer: 'Transfer',
    balance: 'Balance',
    id: 'ID',
    payment: 'Payment',
    addBank: 'Add Bank Card',
    updateBank: 'Update Bank Card',
    email: 'Email',
    finish: 'Finish',
    save: 'Save',
    records: 'Records',
    time: 'Time',
    order: 'Order',
    OTP: 'OTP',
    del: 'Delete',
    copy: 'Copy',
    'agent-rule': 'Agent Rule',
    loadMore: 'Load More',
    'no-more-content': 'No more content available',
  },
  tip: {
    success: 'Successfully',
    copysuccess: 'Copy Successfully',
  },
  text: {},
  defaultPage: {
    noMore: 'No more data',
  },
  home: {
    scratchOff: 'Scratch Off',
    copied: 'Copy successfully, please share with friends',
    tip: {
      beforDownload: 'Please register or login',
    },
  },
  alert: {
    logout: 'LOG OUT',
    sureLogout: 'Are you sure you want to log out?',
  },
  warning: {
    unopen: 'Not yet enabled',
    initializin: 'Initializing, please wait',
  },
  notification: {
    title: 'Notification',
    announcements: 'Announcements',
    systemMessage: 'System Messages',
  },
  vip: {
    currentLevel: 'Current Level',
    collected: 'To be Collected',
    finished: 'To be Finished',
    received: 'Received',
    recharge: 'Recharge',
    move: 'more to reach level',
    title: 'VIP',
    table: {
      level: 'VIP level',
      reward: 'Reward',
      bouns: 'Bouns',
      spin: 'Spin',
    },
    success: 'Receive Successfully',
  },
  filter: {
    today: 'Today',
    yesterday: 'Yesterday',
    days: '{{day}} Days',
    time: 'Select a time',
    name: 'Filter',
  },
  luckyspin: {
    add: 'Add',
    spin: 'SPIN',
    won: 'Won',
    winner: 'Big Winners',
    mySpin: 'My Spin',
    myfree: 'My Free',
    morefree: 'More Free',
    free: 'Free',
    usefreefirst: 'Use Free First',
  },
  invitation: {
    rule: {
      title: 'Invitation Rules',
      paragraph: {
        invite:
          'Invite friends and recharge to get additional platform rewards!',
        after:
          'After being claimed, the rewards will be directly distributed to the wallet balance within 10 minutes.',
      },
      table: {
        invite: 'Invite Account',
        deposit: 'Deposit Account',
        bonus: 'Bonus',
        people: 'People',
      },
      detail: {
        title: 'Rules',
        paragraph1:
          'Only when the number of invited accounts is reached and each account can meet the recharge amount can you receive the bonus.',
        paragraph2:
          'The invitation account meets the requirements, but the recharge amount of the account does not meet the requirements, and the bonus cannot be claimed.',
        paragraph3:
          'Please claim the event bonus within the event period. All bonuses will be cleared after the event expires.',
        paragraph4:
          'Please complete the task within the event period.After the event expires, the invitation record will be cleared.',
      },
    },
    home: {
      title: 'Invite Friends',
      'code-title': 'My invitation code',
      income: 'Cumulative \nincome',
      count: 'Total Invited \nCount',
      record: 'Invitation \nrecord',
      'reward-rules': 'Invitation \nreward rules',
      bouns: 'Bouns',
      join: 'Join Agency',
      'join-tip': 'Be an agent and earn more commission.',
      invites: 'Number of invitees',
      recharges: 'Recharge per people',
      deposit: 'Deposit number',
      'go-complete': 'Go Complete',
      completed: 'Completed',
      rules: 'Rules',
      'copy-text':
        'Link Copied, Go To WhatsApp Or Telegram To Invite Friends Now.',
    },
  },
  proxy: {
    title: 'Agency Center',
    tier: 'Tier{{number}}',
    all: 'All',
    search: 'Search Phone number',
    join: 'Join Agency',
    rules: {
      header: 'Invitation Rules',
      title: 'Promotion partner',
      subtitle: 'ProgramThis activity is valid for a long time',
      paragraph1:
        'There are 4 referral levels when inviting friends. If A invites B, B becomes a level 1 referral of A. If B invites C, C becomes a level 1 referral of B and simultaneously a level 2 referral of A. If C invites D, D becomes a level 1 referral of C, a level 2 referral of B, and a level 3 referral of A.',
      paragraph2:
        'When inviting friends to register, you must send theinvitation link provided or enter the invitation codemanually so that your friends become your level 1subordinates.',
      paragraph3:
        "The invitee registers via the inviter's invitation code andcompletes the deposit, shortly after that thecommission will be received immediately",
      paragraph4:
        'The commission percentage depends on the Agent level. There are four level systems for Agents. Invite more people and update your agent level faster to earn more and more commission',
      paragraph5:
        "The percentage of the recharge bonus received from sub-agents varies depending on the agent's own level",
      'paragraph6-1':
        'The commission percentage depends on themembership level. The higher the membership level, thehigher the bonus percentage. Different game types alsohave different payout percentages',
      'paragraph6-2':
        'The commission rate is specifically explained as follows',
      paragraph7:
        'TOP20 commission rankings will be randomly awardedwith a separate bonus',
      tier: 'tier{{number}}',
      'agent-level': 'Agent Level',
      'commission-level': 'Commission Level',
      'team-member': 'Team\nMember',
      'team-recharge': 'Team\nRecharge',
      'team-betting': 'Team\nBetting',
      lottery: 'Lottery commission percentage',
      scratch: 'Scratch card Slots commission percentage',
      casino: 'Casino commission percentage',
      sports: 'Sports commission percentage',
    },
    filter: {
      recharge: 'Recharge',
      commission: 'Commission',
    },
    user: {
      id: 'ID:',
      'register-date': 'Register Date:',
      'total-user': 'Total Users:',
      'total-commission': 'Total Commission:',
      'bet-user': 'Bet User',
      'betting-user': 'Betting Users',
      'new-user': 'New User',
      'new-user-title': `Its Team's Data`,
      'recharge-user': 'Recharge Users',
      users: 'Users',
      active: 'Active',
      total: 'Total Data',
      today: "Today's User Data",
      commission: 'Commission',
      recharge: 'Recharge',
      betting: 'Betting',
      wonlost: 'Won\\Lost',
      'error-no-performance':
        'This user currently has no performance. \nIs there an issue?',
      contact: 'Contact now',
      'error-no-play':
        "This user hasn't played any games in the last 7 days.\n Is there an issue? ",
      'up-line': 'Up-Line',
    },
    'commission-detail': {
      title: 'Commission Detail',
      total: 'Total Commission',
      sort: 'Commission Sort',
      time: 'Time Sorting',
      highlow: 'From High To Low',
      lowhigh: 'From Low To High',
      phone: 'Phone Number:',
      user: {
        title: 'User Details',
        amount: 'Recharge amount',
        rebate: 'Rebate',
        commission: 'Recharge commission',
        game: 'Game',
        result: 'Result',
      },
    },
    'commission-base': {
      commissionRate: 'Commission Rate',
      commissionType: 'Type',
      commissionTier1: 'Tier 1',
      commissionTier2: 'Tier 2',
      commissionTier3: 'Tier 3',
      commissionTier4: 'Tier 4',
      commissionLv: 'Invite More Friends To Upgrade To Lv',
      commissionLvE: ' Earn Higher Commissions.',
      commissionLv4: 'Earn Higher Commissions',
    },
    'team-report': {
      'new-user': 'New User',
      'betting-users': 'Recharge Users',
      recharge: 'Recharge',
      betting: 'Betting',
      'recharge-commission': 'Recharge Commission',
      'betting-commission': 'Betting commission',
      'my-teams': 'My Teams',
      'user-id': 'User ID',
      user: 'User',
      commission: 'Commission',
      'won-loss': 'Won/Loss',
      won: 'Won',
      loss: 'Loss',
      'user-view': 'User View',
      'up-line': 'Up-line',
      'register-date': 'Register Date:',
      'games-played7': 'Games played in the past 7 days',
      'game-name': 'Game Name',
      tier: 'Tier',
      'no-search': 'No results found in the search',
      'no-data': 'No Data',
      next: 'Next',
    },
    'tier-card': {
      'recharge-amount': 'Recharge amount',
      'recharge-commission': 'Recharge commission',
      'bet-amount': 'Bet amount',
      'bet-commission': 'Bet commission',
      detail: 'Details',
      check: 'Check',
    },
    subordinates: {
      title: 'New subordinates',
      today: 'Today',
      yesterday: 'Yesterday',
      month: 'This Month',
    },
    team: {
      title: 'Team Report',
      grade: 'Grade',
      date: 'Date:',
      time: 'Select Time',
      total: 'Total Data',
      today: 'Today',
      yesterday: 'Yesterday',
      threedays: '3days',
      sevendays: '7days',
      fifteendays: '15days',
      thirtydays: '30days',
      deposit: 'Deposit number',
      betting: 'Betting commission',
      recharge: 'Recharge commission',
      search: 'Enter subordinates phone number',
    },
    home: {
      'agent-id': 'Agent ID:',
      rebate: 'Rebate',
      'my-invitation-code': 'My invitation code',
      'reset-link': 'Reset Link',
      'invitation-link': 'INVITATION LINK',
      'team-report': 'Team report',
      'commission-detail': 'Commission detail',
      'new-subordinates': 'New subordinates',
      'invitation-rules': 'Invitation rules',
      'agent-line-customer-service': 'Agent line customer service',
      'commission-ranking': 'Commission Ranking',
      'agents-whose-commissions-exceed': 'Agents whose commissions exceed ',
      me: 'Me',
    },
    'new-user': {
      title: 'New User',
      today: 'New Users Today:',
      error:
        'No new users yet! \nInvite your friends to earn more commissions.',
      invite: 'Invite now',
    },
    'commission-card': {
      commission: 'Commission Amount',
    },
  },
  'invitation-record': {
    'invitation-record': 'Invitation record',
    status: 'Status',
    'registration-time': 'Registration Time',
    'invited-friends': 'Have not invited any friends yet.',
    invite: 'Invite',
    'bonus-detail': 'Bonus {{bonus}} Details',
    noInvitedFriend: 'No matching invitation record',
  },
  referral: {
    copy: 'Copy',
    invite: 'Invite',
    copied: 'Copy successfully',
  },
  'error-pages': {
    refresh: {
      label: {
        title: 'Something went terribly wrong !',
        'sub-title': 'Please refresh your page',
        'try-again': 'Try Again',
      },
    },
    'no-data': {
      label: {
        title: 'No Data',
      },
    },
    'not-found': {
      label: {
        title: 'The current network signal is poor',
        'sub-title': 'please check your network',
      },
    },
    'no-result': {
      label: {
        title: 'No search result !',
      },
    },
  },
  share: {
    copy: 'Copy',
    invite: 'Invite',
    'copy-success': 'Copy Successfully',
    copied: 'Copy successfully',
  },
  casino: {
    big: 'BIG',
    win: 'WIN',
    detail: {
      title: 'Congratulations to the lucky players',
    },
    winPrize: 'WIN PRIZE',
    playnow: 'Play Now',
    title: 'Casino',
  },
  login: {
    label: {
      login: 'LOGIN',
      skip: 'Skip',
      sign: 'Sign Up',
      next: 'NEXT',
      'select-region': 'Select Region',
      'otp-login': 'OTP Login',
      'password-login': 'Password Login',
      'get-otp': 'GET OTP',
      'set-password': 'Set Password',
    },
    tip: {
      'no-phone': 'Please enter Phone Number',
      'no-password': 'Please enter Password',
      'no-otp': 'Please enter OTP',
      phone: 'Enter Phone Number',
      password: 'Enter Password(6-18)',
      passwordRule:
        'The length must be 8~16 and contain both numbers and letters',
      'confirm-password': 'Confirm Password',
      forgot: 'Forgot your password?',
      'new-account': 'Need an account?',
      'sing-in': 'SIGN UP',
      otp: 'Enter OTP',
      'referral-code': 'Referral Code',
      confrim18: 'I confirm I am 18+',
      'allow-notify':
        'Allow us to notify you important winning information through this phone number.',
      'has-account': 'Already a user?',
      'sign-in': 'LOGIN',
      page18: 'I confirm I am 18+',
      notify:
        'Allow us to notify you important winning informationthrough this mobile number .',
      'pwd-dif': 'The tow passwords is inconsistent',
      modified:
        'The password has been successfully modified. Please log in again',
      'game-not-active':
        'Sorry, the game is under maintenance. Please try again later.',
    },
  },
  paidSuccess: {
    label: {
      title: 'Payment',
      subTitle: 'Paid successfully',
      viewOrder: 'View Order',
      backHome: 'Back Home',
    },
    tip: {
      msg: 'Your tickets have been successfully purchased. Please take note of the draw time and check the results promptly.',
    },
  },
  splash: {
    tip: {
      checkingResources: 'Checking resources...',
      checkingVersion: 'Checking version...',
      downloading: 'Downloading resources...',
      resolve:
        'Resolving resources (this process does not consume the network)',
      alertTitle: 'Update Available',
      restart: 'Restart',
      alertContent:
        'An update is available. Restarting the app to apply the update...',
    },
  },
  recharge: {
    tip: {
      easyPaymentError:
        'Failed to obtain service, please change other payment methods',
    },
  },
  'recharge-page': {
    currentMethod: 'Current Method',
    label: {
      records: 'records',
      min: 'Min',
      max: 'Max',
      channel: 'Recharge channel',
      enter: 'Enter Recharge Amount',
    },
    tip: {
      payMethodTip:
        'Please switch to another method if the current method failed.',
      'money-error': 'Please enter the correct amount',
      'paymethod-error': 'Please select pay method',
    },
  },
  'bets-page': {
    label: {
      share: 'Share',
      result: 'Result',
      bettingTime: 'Betting time',
      shareAwards: 'Share for Reward',
      drawTime: 'Draw time',
      playNow: 'Play Now',
    },
    status: {
      won: 'WON',
      noWin: 'NO WIN',
      toBe: 'TO BE DRAWN...',
    },
  },
  'bets-detail': {
    label: {
      orderDetail: 'Order Details',
      number: 'Number',
      payment: 'Payment',
      result: 'Result',
      gameType: 'Game type',
      panel: 'Panel',
      won: 'Won',
      noWin: 'No win',
      draw: 'Draw',
      totalPoints: 'TOTAL POINT',
      delivery: 'Delivery',
      fee: 'Fee',
      drawRes: 'Draw results',
    },
  },
  'withdraw-page': {
    label: {
      addBank: 'Add Bank Account',
      transferTo: 'Transfer to bank account',
      withdrawAmount: 'Withdrawable Amount',
      withdrawAmountLower: 'Withdrawal amount',
      received: 'Actual amount received:',
      chooseCard: 'Choose a bank card',
      success: 'Withdrawal successful',
    },
    tip: {
      withdrawSubmitted:
        'Your withdrawal has been submitted please be patient.',
      transfer: 'Converting to no withdrawal can earn an ',
      transferSubmit:
        'After clicking Confirm, your withdrawable balance will be converted into Recharge wallet and you will get the corresponding bonus.',
    },
    placeholder: {
      enterAmount: 'Enter Withdrawable amount',
    },
    rules: {
      fee: '<0>Withdrawal will be charged with </0><1>{{percent}}</1><0> payment channel fee</0>',
      times:
        '<0>Each user could withdraw </0><1>{{times}}</1><0> times per day.</0>',
      note: '*Note: withdraw may be delayed due to someBank issues. In this case, the withdrawn amount will be returned to your wallet. Thank you for your patience.',
      limit:
        'We have found some money laundry behaviorsSo limits that recharge amount should be used for betting only. The commission and winninoamount could be withdrawn immediately.',
    },
    error: {
      addCard: 'Please add a bank card first',
      addAmount: 'Please enter the amount',
    },
  },
  'bank-page': {
    label: {
      accountName: 'Account name',
      IFSC: 'IFSC Code',
      accountNum: 'Account Number',
      accountNumAgain: 'Account Number Again',
      UPI: 'Enter UPI Address',
      phoneNumber: 'phone number',
    },
    placeholder: {
      accountName: 'English letters(A-Z.a-z) and space',
      IFSC: 'Please enter the IFSC Code',
      accountNum: '9-17 number, no space',
      UPI: 'Please enter your UPI ID',
      email: 'Please enter the Email',
    },
    del: {
      confirmTitle: 'Delete bank card',
      confirmText: 'Are you sure you want to delete this bank card?',
    },
    error: {
      'check-ifsc': 'Please check your IFSC code',
    },
  },
  'transfer-page': {
    label: {
      withdrawable: 'Withdrawable',
      balance: 'Transferable Balance',
      balanceTitle: 'Transfer to Recharge Wallet',
      willGet: 'Will get',
      total: 'Total Wallet',
    },
    tip: {
      tip: 'After clicking Confirm,your withdrawable balance will be converted into Recharge wallet and you will get the corresponding bonus.',
      success: 'Conversion successful',
    },
  },
  records: {
    label: {
      amount: 'Amount',
      channel: 'Channel',
      received: 'Received Amount',
      commission: 'Commission',
      withdrawChannel: 'Withdraw Channel',
      orderNum: 'Order Number',
      reward: 'Reward',
      order: 'Order',
      game: 'Game',
      types: 'Types',
      receiptTime: 'Time of receipt',
    },
    status: {
      PROCESS: 'In progress...',
      COMPLETE: 'COMPLETE',
      REVIEW: 'In review...',
      FAILED: 'failed',
    },
    tip: {
      error: 'Account error',
      renewTime: 'within 24 hours',
    },
  },
  'bets-share': {
    label: {
      share: 'Share',
      shareSuccess: 'Share successful',
      receiving: 'Congratulations on receiving',
      reward: 'reward',
    },
  },
  datePicker: {
    label: {
      select: 'Select Date',
    },
  },
  rebate: {
    tabs: {
      all: 'ALL',
      finish: 'To Be Finished',
      collect: 'To Be Collected',
      received: 'Received',
    },
    title: 'Rebate',
    filter: 'Filter',
    undone: 'UNDONE',
    unclaimed: 'Unclaimed',
    completed: 'Completed',
    unfinished: 'Unfinished',
    receive: 'Receive',
    received: 'Received',
    today: 'Today',
    yesterday: 'Yesterday',
    betting: 'Estimated Betting:',
    rebate: 'Estimated Rebate:',
    congratulations: 'Congratulations!',
    'get-bonus': 'Get Bonus',
    rule: {
      betting: 'Betting Bonus:',
      'betting-detail':
        '{{name}} will rebate users according to the betting amount. The more you bet, the more you get.',
      activity: 'Activity Description:',
      'activity-detail':
        '{{name}} super Bonus rebate activity is open foryou. Every bet will get a rebate Bonus. No matterif you win or lose, you will get Bonus Daily.',
      actrule: 'Activity Rules:',
      'actrule-detail':
        'Players can claim a betting rebate Bonus DailyBonus claim time is after 00:30 the Next Day.',
      precaution: 'Precautions:',
      'precaution-detail-1':
        '-Only the qualified {{name}} members couldparticipate in activities Every member has to complete account detailsin the Singam Account to claim a rebate Bonus.- Any user involved in illegal activities is strictlyprohibited',
      'precaution-detail-2':
        '-Same phone number, bank account number.Email address, and IP address can not be usedfor multiple accounts',
      'precaution-detail-3':
        '-{{name}} has the right to freeze accounts orcancel payments if found illegal/fraudulentactivities committed by individuals or groups intaking advantage of bonuses, (final bonusdecision belongs to Singam)',
    },
  },
  wallet: {
    title: 'My Wallet',
    total: 'Total Wallet',
    recharge: 'Recharge',
    main: 'Main wallet',
    third: '3rd party wallet',
    transfer: 'Main wallet transfer',
    translation: 'Translation: {{second}}s',
    transComplete: 'Conversion completed',
    transferNav: {
      recharge: 'Recharge',
      withdraw: 'Withdraw',
      transfer: 'Transfer',
    },
  },
  promotion: {
    title: 'Promotions',
    go: 'Go',
    playNow: 'Play Now',
    view: 'View',
    detail: 'Activity details',
    join: 'Join',
    checkin: 'Check in',
    checkinDescription: 'Check in daily to get a lot of rewards',
  },
};
